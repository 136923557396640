<app-navbar-style-one></app-navbar-style-one>

<!-- Start Page Title Area -->
<div class="page-title-area" *ngFor="let content of pageTitle">
    <p>{{content.title}}</p>
    <div class="products-details-dots-slides">
        <ng-container *ngFor="let product of mainProducts; let i = index">
            <div class="dot" 
                 [class.active]="product.name === selectedOption" 
                 (click)="handleOptionSelect(product.name)" 
                 [style.backgroundColor]="product.color">
            </div>
        </ng-container>
    </div>
</div>
<!-- End Page Title Area -->

<!-- Start Products Area -->
<section class="products-area ptb-100">
    <div class="container">
        <div class="row">
            <div class="col-lg-3 col-md-12">
                <aside class="widget-area left-widget-area">
                    <div class="widget widget_top_rated_products">
                        <p class="widget-title">Selected Products ({{ selectedProducts.length }})</p>
                        <div class="selected-products" *ngIf="selectedProducts.length > 0">
                            <article class="item" *ngFor="let product of selectedProducts">
                                <a routerLink="/{{product.detailsLink}}" class="thumb">
                                    <span class="fullimage cover bg1" [style.backgroundImage]="'url(' + product.image + ')'" role="img"></span>
                                </a>
                                <div class="info">
                                    <h4 class="title usmall"><a routerLink="/{{product.detailsLink}}">{{ product.name }}</a></h4>
                                    <div class="star-rating">
                                        <i class='bx bxs-star'></i>
                                        <i class='bx bxs-star'></i>
                                        <i class='bx bxs-star'></i>
                                        <i class='bx bxs-star'></i>
                                        <i class='bx bxs-star'></i>
                                    </div>
                                    <span class="price">{{ product.price }}</span>
                                </div>
                            </article>
                        </div>
                    </div>
                </aside>
            </div>

            <div class="col-lg-9 col-md-12">
                <div class="fiwan-grid-sorting row align-items-center">
                    <div class="col-lg-6 col-md-6 result-count">
                        <div class="d-flex align-items-center">
                            <p class="widget-title">We have <span class="count">{{ totalSubProducts }}</span> products available for this design</p>
                        </div>
                    </div>
                </div>

                <div class="row">
                    <div class="col-4 col-lg-4 col-sm-6 col-md-6" *ngFor="let subProduct of getSubProducts()">
                        <div class="single-products-box" [class.selected]="isSelected(subProduct)" (click)="handleProductSelect(subProduct)">
                            <div class="products-image">
                                <img [src]="subProduct.image" [alt]="subProduct.name">
                            </div>
                            <div class="products-content">
                                <h3>{{ subProduct.name }}</h3>
                            </div>
                        </div>
                    </div>

                    <div class="col-lg-12 col-sm-12 col-md-12">
                        <div class="pagination-area text-center">
                            <!-- <pagination-controls (pageChange)="shopLeftSidebar = $event"></pagination-controls> -->
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
<!-- End Products Area -->

<div class="container">
    <div class="section-title" *ngFor="let Content of sectionTitle;">
        <h2>{{Content.title}}</h2>
        <p>{{Content.paragraph}}</p>
    </div>

    <div class="row">
        <div class="col-lg-3 col-sm-6 col-md-6" *ngFor="let Content of singleProductsItem;">
            <div class="single-products-item">
                <div class="products-image">
                    <a routerLink="/{{Content.detailsLink}}" class="d-block">
                        <img [src]="Content.mainImg" alt="image">
                    </a>
                    <ul class="products-button">
                        <li><a href="#" data-bs-toggle="modal" data-bs-target="#productsQuickView"><i class='bx bx-show-alt'></i></a></li>
                        <li><a href="{{Content.mainImg}}" class="popup-btn"><i class='bx bx-scan'></i></a></li>
                        <li><a routerLink="/{{Content.detailsLink}}"><i class='bx bx-link'></i></a></li>
                    </ul>
                    <a routerLink="/cart" class="add-to-cart-btn"><i class="bx bx-cart"></i> Add To Cart</a>
                </div>
                <div class="products-content">
                    <h3><a routerLink="/{{Content.detailsLink}}">{{Content.title}}</a></h3>
                    <div class="price">
                        <span class="new-price">{{Content.newPrice}}</span>
                        <span class="old-price" *ngIf="Content.oldPrice">{{Content.oldPrice}}</span>
                    </div>
                    <a routerLink="/wishlist" class="add-to-wishlist-btn"><i class="bx bx-heart"></i></a>
                </div>
                <span class="on-sale" *ngIf="Content.sale">{{Content.sale}}</span>
                <div class="out-of-stock" *ngIf="Content.outOfStock">{{Content.outOfStock}}</div>
            </div>
        </div>
    </div>
</div>
import { Component, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';

@Component({
    selector: 'app-contact-page',
    templateUrl: './contact-page.component.html',
    styleUrls: ['./contact-page.component.scss']
})
export class ContactPageComponent implements OnInit {

    successMessage: string = ''; 

    constructor(private http: HttpClient) {}

    ngOnInit(): void {
    }

    pageTitle = [
        {
            bgImage: 'assets/img/page-title-bg.jpg',
            title: 'Contact Us'
        }
    ]

    submit(form){
        console.log(form);
        const { name, email, number, message } = form;
        console.log(name);
        console.log(email);
        console.log(number);
        console.log(message);

        // Make an HTTP POST request to the PHP script with the form data
        this.http.post('/send-email.php', JSON.stringify({ name, email, number, message }), { responseType: 'text' })
            .subscribe(
                (response) => {
                    console.log('Response from PHP script:', response);
                    // Optionally, reset the form after successful submission
                    // this.resetForm();
                    if (response === 'Email sent successfully') {
                        this.successMessage = 'Your message has been sent successfully!'; // Set success message
                    }
                  },
                (error) => {
                    console.log('Error sending form data to PHP script:', error);
                }
            );
    }

}
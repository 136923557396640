import { Component, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { OwlOptions } from 'ngx-owl-carousel-o';

interface SubProduct {
  name: string;
  id: number;
  image: string;
  price?: string;
  detailsLink?: string;
}

interface MainProduct {
  name: string;
  id?: number;
  image: string;
  color: string; // Add this property
  subProducts: SubProduct[];
}

@Component({
  selector: 'app-image-form',
  templateUrl: './image-form.component.html',
  styleUrls: ['./image-form.component.scss']
})
export class ImageFormComponent implements OnInit {
  mainProducts: MainProduct[] = [];
  selectedOption: string = '';
  selectedProducts: SubProduct[] = [];
  currentSlide: number = 0;
  successMessage: string = '';
  totalSubProducts: number = 0;

  constructor(private http: HttpClient) { }

  ngOnInit(): void {
    this.http.get<MainProduct[]>('assets/json/products.json').subscribe(data => {
      this.mainProducts = data['mainProducts'];
      if (this.mainProducts.length > 0) {
        this.selectedOption = this.mainProducts[0].name; // Set the selected option to the first main product
        this.updateSubProductCount();
      }
    });
    this.loadSelectedProducts();
  }

  pageTitle = [
    {
      bgImage: 'assets/img/page-title-bg.jpg',
      title: 'Choose Decorations and Products'
    }
  ];

  productsDetailsImageSlidesOptions: OwlOptions = {
    loop: true,
    nav: true,
    dots: false,
    autoplayHoverPause: true,
    autoplay: false,
    margin: 30,
    navText: [
      "<i class='bx bx-left-arrow-alt'></i>",
      "<i class='bx bx-right-arrow-alt'></i>"
    ]
  };

  handleOptionSelect(option: string): void {
    this.selectedOption = option;
    this.updateSubProductCount();
  }

  handleProductSelect(subProduct: SubProduct): void {
    const index = this.selectedProducts.findIndex(product => product.id === subProduct.id);
    if (index === -1) {
      this.selectedProducts.push(subProduct);
    } else {
      this.selectedProducts.splice(index, 1);
    }
    this.saveSelectedProducts();
  }

  isSelected(subProduct: SubProduct): boolean {
    return this.selectedProducts.some(product => product.id === subProduct.id);
  }

  handleSubmit(): void {
    console.log('Selected Products:', this.selectedProducts);
    this.successMessage = 'Products have been successfully submitted.';
  }

  prevSlide(): void {
    this.currentSlide = (this.currentSlide > 0) ? this.currentSlide - 1 : this.mainProducts.length - 1;
  }

  nextSlide(): void {
    this.currentSlide = (this.currentSlide < this.mainProducts.length - 1) ? this.currentSlide + 1 : 0;
  }

  getSubProducts(): SubProduct[] {
    const selectedProduct = this.mainProducts.find(product => product.name === this.selectedOption);
    return selectedProduct ? selectedProduct.subProducts : [];
  }

  updateSubProductCount(): void {
    const selectedProduct = this.mainProducts.find(product => product.name === this.selectedOption);
    this.totalSubProducts = selectedProduct ? selectedProduct.subProducts.length : 0;
  }

  saveSelectedProducts(): void {
    localStorage.setItem('selectedProducts', JSON.stringify(this.selectedProducts));
  }

  loadSelectedProducts(): void {
    const savedProducts = localStorage.getItem('selectedProducts');
    if (savedProducts) {
      this.selectedProducts = JSON.parse(savedProducts);
    }
  }
}
